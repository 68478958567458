import React, { Component } from "react";
//import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//import Button from '@material-ui/core/Button';

import moment from "moment";
//import Plus from "mdi-material-ui/Plus";
//import PowerPlug from "mdi-material-ui/PowerPlug";

import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import Admin from "../../components/Admin";
import DeviceStore from "../../stores/DeviceStore";
import theme from "../../theme";
//import TitleBarButton from "../../components/TitleBarButton";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBar from "../../components/TitleBar";
//import AutocompleteSelect from "../../components/AutocompleteSelect";
import ApplicationStore from "../../stores/ApplicationStore";
//import TextField from "@material-ui/core/TextField/TextField";
import TitleBarAddButton from "../../components/TitleBarAddButton";
import Delete from "@material-ui/core/SvgIcon/SvgIcon";
import TitleBarDeleteButton from "../../components/TitleBarDeleteButton";


const styles = {
  cell: {
    color: "red",
  },
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing.unit,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
};


class ListDevices extends Component {
  constructor() {
    super();
    this.getApplicationOption = this.getApplicationOption.bind(this);
    this.getApplicationOptions = this.getApplicationOptions.bind(this);
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getApplicationOption(id, callbackFunc) {
    ApplicationStore.get(id, resp => {
      callbackFunc({ label: resp.application.name, value: resp.application.id });
    });
  }

  getApplicationOptions(search, callbackFunc) {
    ApplicationStore.list(0, null, 999, 0, resp => {
      const options = resp.result.map((dp, i) => { return { label: dp.name, value: dp.id } });
      callbackFunc(options);
    });
  }

  static deleteDevice(devEUI) {
    return function () {
      if (window.confirm("Are you sure you want to delete this device?")) {
        DeviceStore.delete(devEUI, resp => {
          window.location.reload();
          //this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`);
        });
      }
    }
  }

  getPage(limit, offset, callbackFunc) {
    DeviceStore.list({
      applicationID: this.props.match.params.applicationID,
      limit: limit,
      offset: offset,
    }, callbackFunc);
  }

  getRow(obj) {
    let lastseen = "n/a";
    //let margin = "n/a";
    //let battery = "n/a";

    if (obj.lastSeenAt !== undefined && obj.lastSeenAt !== null) {
      lastseen = moment(obj.lastSeenAt).fromNow();
    }

    /*if (!obj.deviceStatusExternalPowerSource && !obj.deviceStatusBatteryLevelUnavailable) {
      battery = `${obj.deviceStatusBatteryLevel}%`
    }

    if (obj.deviceStatusExternalPowerSource) {
      battery = <PowerPlug />;
    }

    if (obj.deviceStatusMargin !== undefined && obj.deviceStatusMargin !== 256) {
      margin = `${obj.deviceStatusMargin} dB`;
    }*/

    return (
      <TableRow key={obj.devEUI}>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/end-devices/${obj.devEUI}`}>{obj.name}</TableCellLink>
        <TableCell>{obj.devEUI}</TableCell>
        {/*<TableCell>00000000-0000-0000-0000-{obj.applicationID.padStart(12, "0")}</TableCell>*/}
        {moment().unix() - moment(obj.lastseen).unix() >= 60 * 60 * 24 &&
          <TableCell className={this.props.classes.cell}>{moment(obj.lastseen).format("YYYY-MM-DD HH:mm:ss.SSS")}</TableCell>}
        {moment().unix() - moment(obj.lastseen).unix() < 60 * 60 * 24 &&
          <TableCell>{moment(obj.lastseen).format("YYYY-MM-DD HH:mm:ss.SSS")}</TableCell>}
        <TableCell align="right"><TitleBarDeleteButton
          label="Delete"
          icon={<Delete />}
          color="secondary"
          onClick={ListDevices.deleteDevice(obj.devEUI)}
        /></TableCell>
        {/*<TableCell>{margin}</TableCell>
        <TableCell>{battery}</TableCell>*/}
      </TableRow>
    );
  }

  render() {
    return (
      <Grid container spacing={24}>
        <TitleBar
          buttons={
            <Admin organizationID={this.props.match.params.organizationID}>
              <Grid item xs={12} className={this.props.classes.buttons}>
                <TitleBarAddButton to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/end-devices/create`} />
              </Grid>
            </Admin>
          }
        >
          {!this.props.match.params.applicationID && <TitleBarTitle title="End Devices" />}
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>EUI</TableCell>
                {/*<TableCell>Application</TableCell>*/}
                <TableCell>Last seen</TableCell>
                <TableCell></TableCell>
                {/*<TableCell>Link margin</TableCell>
                <TableCell>Battery</TableCell>*/}
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListDevices);
