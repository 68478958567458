import { EventEmitter } from "events";
import RobustWebSocket from "robust-websocket";
import axios from "axios"

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import { checkStatus, errorHandler, errorHandlerIgnoreNotFound } from "./helpers";
import dispatcher from "../dispatcher";
import { EventSourcePolyfill } from "event-source-polyfill";


class DeviceStore extends EventEmitter {
  constructor() {
    super();
    this.wsDataStatus = null;
    this.wsFramesStatus = null;
    this.swagger = new Swagger("/swagger/device.swagger.json", sessionStore.getClientOpts());
  }

  getWSDataStatus() {
    return this.wsDataStatus;
  }

  getWSFramesStatus() {
    return this.wsFramesStatus;
  }

  create(device, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.Create({
        body: {
          device: device,
        },
      })
        .then(checkStatus)
        .then(resp => {
          if (device.nwkKey == null && device.appKey != null) {
            const keys = { devEUI: device.devEUI, nwkKey: device.appKey, appKey: device.appKey };
            this.createKeys(keys, callbackFunc);
          } else if (device.nwkKey != null && device.appKey == null) {
            const keys = { devEUI: device.devEUI, nwkKey: device.nwkKey, appKey: device.nwkKey };
            this.createKeys(keys, callbackFunc);
          } else if (device.nwkKey != null && device.appKey != null) {
            const keys = { devEUI: device.devEUI, nwkKey: device.nwkKey, appKey: device.appKey };
            this.createKeys(keys, callbackFunc);
          } else {
            this.notify("added");
            callbackFunc(resp.obj);
          }
        })
        .catch(errorHandler);
    });
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.Get({
        dev_eui: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.getKeys(id, r => {
            resp.obj.device.nwkKey = r.nwkKey;
            resp.obj.device.appKey = r.appKey;
            callbackFunc(resp.obj);
          });
        })
        .catch(errorHandler);
    });
  }

  update(device, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.Update({
        "device.dev_eui": device.devEUI,
        body: {
          device: device,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("saved");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.Delete({
        dev_eui: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.notify("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  list(filters, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.List(filters)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getKeys(devEUI, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.GetKeys({
        dev_eui: devEUI,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(error => {
          callbackFunc({ deviceKeys: { devEUI: devEUI, nwkKey: null, appKey: null } });
        });
    });
  }

  createKeys(deviceKeys, callbackFunc) {
    this.swagger.then(client => {
      console.log(deviceKeys);
      client.apis.DeviceService.CreateKeys({
        "device_keys.dev_eui": deviceKeys.devEUI,
        body: {
          deviceKeys: deviceKeys,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notifyKeys("added");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updateKeys(deviceKeys, callbackFunc) {
    this.swagger.then(client => {
      console.log(deviceKeys);
      client.apis.DeviceService.UpdateKeys({
        "device_keys.dev_eui": deviceKeys.devEUI,
        body: {
          deviceKeys: deviceKeys,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notifyKeys("saved");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getActivation(devEUI, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.GetActivation({
        "dev_eui": devEUI,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandlerIgnoreNotFound);
    });
  }

  activate(deviceActivation, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.Activate({
        "device_activation.dev_eui": deviceActivation.devEUI,
        body: {
          deviceActivation: deviceActivation,
        },
      })
        .then(checkStatus)
        .then(resp => {
          dispatcher.dispatch({
            type: "CREATE_NOTIFICATION",
            notification: {
              type: "success",
              message: "End device activation has been saved",
            },
          });
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getRandomDevAddr(devEUI, callbackFunc) {
    this.swagger.then(client => {
      client.apis.DeviceService.GetRandomDevAddr({
        dev_eui: devEUI,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getDataLogsConnection(devEUI, onData) {
    const loc = window.location;
    const wsURL = (() => {
      const wsProtocol = loc.protocol === "https:" ? "wss:" : "ws:";
      return `${wsProtocol}//application-server.lorawan.ocupoly.com:443/api/devices/${devEUI}/events`;
    })();

    const conn = new RobustWebSocket(wsURL, ["Bearer", sessionStore.getToken()], {});

    conn.addEventListener("open", () => {
      console.log('connected to', wsURL);
      this.wsDataStatus = "CONNECTED";
      this.emit("ws.status.change");
    });

    conn.addEventListener("message", (e) => {
      const msg = JSON.parse(e.data);
      if (msg.error !== undefined) {
        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message: msg.error.message,
          },
        });
      } else if (msg.result !== undefined) {
        onData(msg.result);
      }
    });

    conn.addEventListener("close", () => {
      console.log('closing', wsURL);
      this.wsDataStatus = null;
      this.emit("ws.status.change");
    });

    conn.addEventListener("error", () => {
      console.log("error");
      this.wsDataStatus = "ERROR";
      this.emit("ws.status.change");
    });

    return conn;
  }

  getUplinkPackets(devEUI, onData) {
    const tail = 100;
    const accessToken = sessionStore.getToken2();
    const conn = new EventSourcePolyfill("https://lorawan.ocupoly.com/api/v4/uplink-packets/end-devices/" + devEUI + "?filter[tail]=" + tail, {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    conn.onmessage = result => {
      if (result.data !== "") {
        const data = JSON.parse(result.data);
        onData(data);
      }
    };

    return conn;
  }

  getDownlinkPackets(devEUI, onData) {
    const tail = 100;
    const accessToken = sessionStore.getToken2();
    const conn = new EventSourcePolyfill("https://lorawan.ocupoly.com/api/v4/downlink-packets/end-devices/" + devEUI + "?filter[tail]=" + tail, {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    conn.onmessage = result => {
      if (result.data !== "") {
        const data = JSON.parse(result.data);
        onData(data);
      }
    };

    return conn;
  }

  getFrameLogsConnection(devEUI, onData) {
    const loc = window.location;
    const wsURL = (() => {
      const wsProtocol = loc.protocol === "https:" ? "wss:" : "ws:";
      return `${wsProtocol}//application-server.lorawan.ocupoly.com:443/api/devices/${devEUI}/frames`;
    })();

    const conn = new RobustWebSocket(wsURL, ["Bearer", sessionStore.getToken()], {});

    conn.addEventListener("open", () => {
      this.wsFramesStatus = "CONNECTED";
      this.emit("ws.status.change");
    });

    conn.addEventListener("message", (e) => {
      const msg = JSON.parse(e.data);
      if (msg.error !== undefined) {
        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message: msg.error.message,
          },
        });
      } else if (msg.result !== undefined) {
        onData(msg.result);
      }
    });

    conn.addEventListener("close", () => {
      console.log('closing', wsURL);
      this.wsFramesStatus = null;
      this.emit("ws.status.change");
    });

    conn.addEventListener("error", (e) => {
      console.log("error", e);
      this.wsFramesStatus = "ERROR";
      this.emit("ws.status.change");
    });

    return conn;
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "End device has been " + action,
      },
    });
  }

  notifyKeys(action) {
    /*dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "device keys have been " + action,
      },
    });*/
  }
}

const deviceStore = new DeviceStore();
export default deviceStore;
