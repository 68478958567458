import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

//import Plus from "mdi-material-ui/Plus";

import moment from "moment";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarAddButton from "../../components/TitleBarAddButton";
import DataTable from "../../components/DataTable";
import Admin from "../../components/Admin";
import GatewayStore from "../../stores/GatewayStore";
import TitleBarDeleteButton from "../../components/TitleBarDeleteButton";
import Delete from "@material-ui/core/SvgIcon/SvgIcon";
import { Typography } from "@material-ui/core";
//import DeviceProfileStore from "../../stores/DeviceProfileStore";


class GatewayRow extends Component {
  constructor() {
    super();

    this.state = {};
  }

  static deleteGateway(id) {
    return function () {
      if (window.confirm("Are you sure you want to delete this gateway?")) {
        GatewayStore.delete(id, resp => {
          window.location.reload();
          //this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`);
        });
      }
    }
  }

  componentWillMount() {
    const start = moment().subtract(29, "days").toISOString();
    const end = moment().toISOString();

    // GatewayStore.getStats(this.props.gateway.id, start, end, resp => {
    //   let stats = {
    //     labels: [],
    //     datasets: [
    //       {
    //         data: [],
    //         fillColor: "rgba(33, 150, 243, 1)",
    //       },
    //     ],
    //   };

    //   for (const row of resp.result) {
    //     stats.labels.push(row.timestamp);
    //     stats.datasets[0].data.push(row.rxPacketsReceivedOK + row.txPacketsEmitted);
    //   }

    //   this.setState({
    //     stats: stats,
    //   });
    // });
  }

  render() {
    /*const options = {
    scales: {
      xAxes: [{display: false}],
      yAxes: [{display: false}],
    },
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    responsive: false,
    animation: {
      duration: 0,
    },
  };*/

    return (
      <TableRow>
        <TableCellLink to={`/organizations/${this.props.gateway.organizationID}/gateways/${this.props.gateway.id}`}>{this.props.gateway.name}</TableCellLink>
        <TableCell>{this.props.gateway.id}</TableCell>
        {moment().unix() - moment(this.props.gateway.lastSeenAt).unix() >= 90 &&
          <TableCell className={this.props.classes.cell}>{moment(this.props.gateway.lastSeenAt).format("YYYY-MM-DD HH:mm:ss.SSS")}</TableCell>}
        {moment().unix() - moment(this.props.gateway.lastSeenAt).unix() < 90 &&
          <TableCell>{moment(this.props.gateway.lastSeenAt).format("YYYY-MM-DD HH:mm:ss.SSS")}</TableCell>}
        <TableCell align="right"><TitleBarDeleteButton
          label="Delete"
          icon={<Delete />}
          color="secondary"
          onClick={GatewayRow.deleteGateway(this.props.gateway.id)}
        /></TableCell>
      </TableRow>
    );
  }
}

const styles = {
  cell: {
    color: "red",
  },
  chart: {
    width: 380,
  },
};

class ListGateways extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    GatewayStore.list("", this.props.match.params.organizationID, limit, offset, callbackFunc);
  }

  getRow(obj) {
    return (
      <GatewayRow key={obj.id} gateway={obj} classes={this.props.classes} />
    );
  }

  render() {
    return (
      <Grid container spacing={24}>
        <TitleBar
          buttons={
            <Admin organizationID={this.props.match.params.organizationID}>
              <TitleBarAddButton to={`/organizations/${this.props.match.params.organizationID}/gateways/create`} />
            </Admin>
          }
        >
          <TitleBarTitle title="Gateways" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Last Seen</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListGateways);
